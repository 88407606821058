module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"OpenUI5 Website","short_name":"OpenUI5","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"data/logo.png","include_favicon":false,"icons":[{"src":"favicon/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"favicon/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"favicon/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"favicon/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"favicon/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"favicon/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"favicon/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"favicon/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"8f8e47ea8af460775b722cf9f4ee0310"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
